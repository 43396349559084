* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --accent: #98BF64;
  --dark-accent: #005C29;
  --red: #D0312D;
  --light-red: #BC544B;
}
